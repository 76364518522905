import React from 'react';
import './App.css';
import Pomodoro from './components/Pomodoro';

function App() {
  return (
    <div className="ScreenCenter">
      <header>
        <Pomodoro />
      </header>
    </div>
  );
}

export default App;
