import React from 'react';
import './Pomodoro.css';
import * as Timer from './Timer';
import * as Settings from './Settings';

const timerColors: {[k: string]: string} = {
    Focus: "var(--green-4)",
    Break: "var(--indigo-3)",
}

interface PomodoroState {
    periods: Settings.Period[];
    currentPeriodCount: number; // This can exceed periods.length.
    sendNotifications: boolean;
    showSettings: boolean;
}

class Pomodoro extends React.Component {
    state: PomodoroState = {
        periods: [ {task: "Focus", seconds: 25*60}
                 , {task: "Break", seconds: 5*60}
                 , {task: "Focus", seconds: 25*60}
                 , {task: "Break", seconds: 5*60}
                 , {task: "Focus", seconds: 25*60}
                 , {task: "Break", seconds: 5*60}
                 , {task: "Focus", seconds: 25*60}
                 , {task: "Break", seconds: 20*60}
                 ],
        currentPeriodCount: 0,
        sendNotifications: false,
        showSettings: false,
    }
    componentDidMount() {
        const storedPeriods = Settings.getStoredSettings();
        if (storedPeriods !== undefined) {
            this.setState({
                periods: storedPeriods,
            });
        }
    }
    render(): JSX.Element {
        const currentPeriodIx = this.state.currentPeriodCount % this.state.periods.length;
        const currentPeriod: Settings.Period = this.state.periods[currentPeriodIx];
        return (
            <div className="PomodoroContainer">
                <Settings.RenderSettings show={this.state.showSettings} periods={this.state.periods}
                                         onSubmit={(periods: Settings.Period[]) => {this.setState({periods: periods,
                                                                                                   currentPeriodCount: periods.length*100}) }}
                                         onClose={() => {this.setState({showSettings: false})}}
/>
                <RenderPeriods periods={this.state.periods} currentPeriodIndex={currentPeriodIx} onClick={this.setPeriod.bind(this)}/>
                <div className="Relative">
                    <Settings.RenderSettingsIcon show={this.state.showSettings} onClick={() => this.setState({showSettings: !this.state.showSettings})}/>
                </div>
                <Timer.Timer key={timerKey(this.state.periods, this.state.currentPeriodCount)}
                             timerLength={currentPeriod.seconds}
                             timerTitle={currentPeriod.task}
                             timerRunningColor={timerColors[currentPeriod.task]}
                             finishCallback={() => this.timerFinished()}
                             startTimerOnMount={this.state.currentPeriodCount > 0}
                             />
                <this.RenderNotifications />
            </div>
        );
    }

    /**
     * Handles the callback from Timer when the timer is finished.
     */
    timerFinished(): void {
        const nextPeriodCount = this.state.currentPeriodCount + 1;
        if(this.state.sendNotifications) {
            const nextPeriodIx = nextPeriodCount % this.state.periods.length;
            const nextPeriod: Settings.Period = this.state.periods[nextPeriodIx];
            switch(nextPeriod.task) {
                case "Focus":
                    new Notification(`It is time to focus`);
                    break;
                case "Break":
                    new Notification(`It is time for a break`);
                    break;
            }
        }
        this.setState({
            currentPeriodCount: nextPeriodCount,
        });
    }

    /**
     * Render the notification checkbox.
     */
    RenderNotifications = (): JSX.Element => {
        let notificationsCSS = "NotificationsDefault";
        let notificationStatus = "";
        switch(Notification.permission) {
            case "default":
                notificationsCSS = "NotificationsDefault";
                notificationStatus = "Notifications are set to Default";
                break;
            case "granted":
                notificationsCSS = "NotificationsGranted";
                notificationStatus = "Notifications are Granted";
                break;
            case "denied":
                notificationsCSS = "NotificationsDenied";
                notificationStatus = "Notifications are Denied";
                break;
        }

        return (
            <div className={`Notifications ${notificationsCSS}`}>
                <input id="notificationCheckbox"
                       type="checkbox"
                       checked={this.state.sendNotifications}
                       onChange={(e) => this.notificationOnChange(e.target.checked)}
                       />
                <label htmlFor="notificationCheckbox">Get notification when timer runs out.</label> {notificationStatus}
            </div>

        )
    }

    /**
     * Handle the checkbox of when notification changes.
     */
    notificationOnChange(value: any) {
        const sendNotifications = value;
        if (sendNotifications) {
            Notification.requestPermission().then( (result) => {
                this.forceUpdate(); // Rerender to get the correct styling.
            });
        }
        this.setState({
            sendNotifications,
        });
    }

    /**
     * Set a specific period.
     */
    setPeriod(periodIx: number) {
        // Avoid setting currentPeriodCount to 0, since it does not start automatically.
        const currentPeriodWrap = Math.floor(this.state.currentPeriodCount / this.state.periods.length);
        const nextPeriodWrap = (currentPeriodWrap % 1000) + 1;
        const currentPeriodCount = periodIx + (nextPeriodWrap * this.state.periods.length);
        this.setState({
            currentPeriodCount
        });
    }
}

interface RenderPeriodsProps {
    periods: Settings.Period[];
    currentPeriodIndex: number;
    onClick: (periodIx: number) => void;
}

/**
 * Render the periods of the pomodoro sequence.
 */
const RenderPeriods = (props: RenderPeriodsProps): JSX.Element => {
    return (
        <div className="PomodoroPeriods">
            {props.periods.map( (period: Settings.Period, ix: number) => {
                return <RenderPeriod key={ix} period={period} isActive={ix === props.currentPeriodIndex} onClick={() => props.onClick(ix)}/>
            })}
        </div>
    );
}

interface RenderPeriodProps {
    period: Settings.Period;
    isActive: boolean;
    onClick: () => void;
}

/**
 * Render one period in the pomodoro sequence.
 */
const RenderPeriod = (props: RenderPeriodProps): JSX.Element => {
    const periodColor = props.period.task === "Focus" ? "PomodoroPeriodFocus" : "PomodoroPeriodBreak";
    const periodActive = props.isActive ? "PomodoroPeriodActive" : "";
    return (
        <div className={`PomodoroPeriod ${periodColor} ${periodActive}`} onClick={() => props.onClick()}>
            <span>{props.period.task}</span>
            <span>{Timer.prettyPrintTimer(props.period.seconds)}</span>
        </div>
    );
}

/**
 * Create a unique key for the timer to make it rerender when
 * the periods change.
 */
const timerKey = (periods: Settings.Period[], currentPeriodCount: number): string => {
    return periods.reduce((acc, cur) => {return acc+cur.seconds}, "k") + currentPeriodCount;
}

export default Pomodoro;
