import React from 'react';
import './Settings.css'
import * as Timer from './Timer';
//import { images } from '../images/images';
import SvgCogwheel from '../images/SvgCogwheel';

export type Task = "Focus" | "Break";

export interface Period {
    task: Task;
    seconds: Timer.Seconds;
}

/**
 * The stored data structure in local storage.
 */
interface StorageData {
    focus: number; // In seconds
    smallBreak: number; // In seconds
    longBreak: number; // In seconds
    focusPeriods: number;
}

/**
 * The key used for storing the settings in local storage.
 */
const storageKey = "PomodoroSettings";

export interface SettingsProps {
    show: boolean;
    periods: Period[];
    onSubmit: (periods: Period[]) => void;
    onClose: () => void;
}

interface RenderSettingsState {
    focusMin: string;
    focusSec: string;
    smallBreakMin: string;
    smallBreakSec: string;
    longBreakMin: string;
    longBreakSec: string;
    focusPeriods: string;
}

export class RenderSettings extends React.Component<SettingsProps> {
    state: RenderSettingsState = {
        focusMin: "0",
        focusSec: "0",
        smallBreakMin: "0",
        smallBreakSec: "0",
        longBreakMin: "0",
        longBreakSec: "0",
        focusPeriods: "0",
    }
    componentDidMount() {
        const storedSettings = readLocalStorage();
        if (storedSettings) {
            const newState: RenderSettingsState = storageData2RenderedSettingsState(storedSettings);
            this.setState({...newState});
        } else {
            this.resetToDefaults();
        }
    }
    render() {
        let dialogCss = "SettingsDialog"
        let open = false
        if (this.props.show) {
            dialogCss += " SettingsDialogOpen"
            open = true
        }
        return (
            <dialog className={dialogCss} open={open} onClose={() => this.props.onClose()}>
                <form method="dialog" onSubmit={() => this.submitForm()}>
                <div className="SettingsRow">
                    <label>Focus time:</label>
                    <div>
                        <RenderInputNumber name="focusMin" value={this.state.focusMin} onChange={this.updateInputNumber} />
                        :
                        <RenderInputNumber name="focusSec" value={this.state.focusSec} onChange={this.updateInputNumber} />
                    </div>
                </div>
                <div className="SettingsRow">
                    <label>Small break time:</label>
                    <div>
                        <RenderInputNumber name="smallBreakMin" value={this.state.smallBreakMin} onChange={this.updateInputNumber} />
                        :
                        <RenderInputNumber name="smallBreakSec" value={this.state.smallBreakSec} onChange={this.updateInputNumber} />
                    </div>
                </div>
                <div className="SettingsRow">
                    <label>Long break time:</label>
                    <div>
                        <RenderInputNumber name="longBreakMin" value={this.state.longBreakMin} onChange={this.updateInputNumber} />
                        :
                        <RenderInputNumber name="longBreakSec" value={this.state.longBreakSec} onChange={this.updateInputNumber} />
                    </div>
                </div>
                <div className="SettingsRow">
                    <label>Focus periods:</label>
                    <div>
                        <RenderInputNumber name="focusPeriods" value={this.state.focusPeriods} onChange={this.updateInputNumber} />
                    </div>
                </div>
                <div className="SettingsFlexbox">
                    <input className="Save" type="submit" value="Save"/>
                    <input className="Reset" type="button" value="Reset to Defaults" onClick={() => this.resetToDefaults()}/>
                    <input className="Close" type="button" value="Close" onClick={() => this.props.onClose()}/>
                </div>
                </form>
            </dialog>
        );
    }

    /**
     * Set the defaults values.
     */
    resetToDefaults = () => {
        this.setState({
            focusMin: "25",
            focusSec: "0",
            smallBreakMin: "5",
            smallBreakSec: "0",
            longBreakMin: "20",
            longBreakSec: "0",
            focusPeriods: "4",
        });
    }

    /**
     * Updates the state for the input element.
     * The input element must have the name attribute!
     */
    updateInputNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }
    submitForm = () => {
        this.props.onSubmit(
                generatePeriods( this.state.focusMin
                               , this.state.focusSec
                               , this.state.smallBreakMin
                               , this.state.smallBreakSec
                               , this.state.longBreakMin
                               , this.state.longBreakSec
                               , this.state.focusPeriods));
        const storageData: StorageData = {
            focus: Number(this.state.focusMin)*60 + Number(this.state.focusSec),
            smallBreak: Number(this.state.smallBreakMin)*60 + Number(this.state.smallBreakSec),
            longBreak: Number(this.state.longBreakMin)*60 + Number(this.state.longBreakSec),
            focusPeriods: Number(this.state.focusPeriods),
        }
        localStorage.setItem(storageKey, JSON.stringify(storageData));
    }
}

interface RenderInputNumberProps {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RenderInputNumber = (props: RenderInputNumberProps) => {
    return (
        <input type="number" min="0" className="NoSpinner"
               name={props.name} value={props.value} onChange={(e) => props.onChange(e)}/>
    )
}

const generatePeriods = ( focusMin: string
                                  , focusSec: string
                                  , smallBreakMin: string
                                  , smallBreakSec: string
                                  , longBreakMin: string
                                  , longBreakSec: string
                                  , focusPeriods: string): Period[] => {
    const periods: Period[] = [];
    for (let i = 0; i < Number(focusPeriods)-1; i++) {
        const focusPeriod: Period = {task: "Focus", seconds: Number(focusMin)*60 + Number(focusSec)}
        periods.push(focusPeriod);
        const smallBreak: Period = {task: "Break", seconds: Number(smallBreakMin)*60 + Number(smallBreakSec)}
        periods.push(smallBreak);
    }
    const focusPeriod2: Period = {task: "Focus", seconds: Number(focusMin)*60 + Number(focusSec)}
    periods.push(focusPeriod2);
    const longBreak: Period = {task: "Break", seconds: Number(longBreakMin)*60 + Number(longBreakSec)}
    periods.push(longBreak);
    return periods;
}

interface SettingsIconProps {
    show: boolean;
    onClick: () => void;
}

export const RenderSettingsIcon = (props:SettingsIconProps): JSX.Element => {
    let cogwheelcss = "Cogwheel";
    cogwheelcss += props.show ? " CogwheelRotate" : "";
    return (
        <SvgCogwheel className={cogwheelcss} onClick={() => props.onClick()}/>
    );
}

const readLocalStorage = (): StorageData | undefined => {
    const settingsStr = localStorage.getItem(storageKey);
    if (!settingsStr) {
        return undefined;
    }
    try {
        return JSON.parse(settingsStr);
    } catch (error) {
        console.log(`Unable to parse the settings from localStorage: ${error}`);
        return undefined;
    }
}

const storageData2RenderedSettingsState = (storedSettings: StorageData): RenderSettingsState => {
    const newState = {
                focusMin: "" + Math.floor(storedSettings.focus / 60),
                focusSec: "" + storedSettings.focus % 60,
                smallBreakMin: "" + Math.floor(storedSettings.smallBreak / 60),
                smallBreakSec: "" + storedSettings.smallBreak % 60,
                longBreakMin: "" + Math.floor(storedSettings.longBreak / 60),
                longBreakSec: "" + storedSettings.longBreak % 60,
                focusPeriods: "" + storedSettings.focusPeriods,
    }
    return newState;
}

export const getStoredSettings = (): Period[] | undefined => {
    const storedSettings = readLocalStorage();
    if (storedSettings) {
        const newState = storageData2RenderedSettingsState(storedSettings);
        const periods = generatePeriods( newState.focusMin
                                       , newState.focusSec
                                       , newState.smallBreakMin
                                       , newState.smallBreakSec
                                       , newState.longBreakMin
                                       , newState.longBreakSec
                                       , newState.focusPeriods);
        return periods;
    }
    return undefined;
}

export default RenderSettings;
