import React from 'react';
import './Timer.css';

export type Seconds = number;

interface TimerProps {
    timerLength: Seconds;
    timerTitle?: string;
    timerRunningColor?: string;
    startTimerOnMount?: boolean;
    finishCallback?: () => void;
}

interface TimerState {
    timerLeft: Seconds;
    timerStarted: Date | undefined;
    timeoutId: ReturnType<typeof setInterval> | undefined;
}

/**
 * This is a generic timer which count down from its timerLength to 0.
 * When the timer reaches 0, finishCallback is called.
 */
export class Timer extends React.Component<TimerProps> {
    state: TimerState = {
        timerLeft: this.props.timerLength,
        timerStarted: undefined,
        timeoutId: undefined,
    }

    componentDidMount() {
        if (this.props.startTimerOnMount) {
            this.startTimer();
        }
        this.updateTitle(this.props.timerLength);
    }

    componentWillUnmount() {
        const timeoutId = this.state.timeoutId;
        if (timeoutId !== undefined) {
            clearInterval(timeoutId);
        }
    }

    render(): JSX.Element {
        const timerRunning = this.state.timerStarted !== undefined ?
                             this.props.timerRunningColor ?? "var(--green-4)" : "var(--red-4)";
        const timerStyle = {
            background: timerRunning,
            transition: "background 1s",
        }
        let timerLeft = this.state.timerLeft;
        if (this.state.timerStarted !== undefined) {
            timerLeft = this.calcTimerLeft();
        }
        const playPauseIcon = this.state.timerStarted === undefined ? "PlayIcon" : "PauseIcon";
        return (
            <div className={"TimerBox"} style={timerStyle}>
              <RenderTimerValue timerValue={prettyPrintTimer(timerLeft)} />
              <div className="TimerCenter">
                <div>
                    <div className="CircleSmall" onClick={() => this.resetTimer()}>
                        <div className="PrevIcon"></div>
                    </div>
                </div>
                <div>
                    <div className="Circle" onClick={() => this.toggleStartStop()}>
                        <div className={playPauseIcon}></div>
                    </div>
                </div>
                <div>
                    <div className="CircleSmall" onClick={() => this.finishTimer()}>
                        <div className="NextIcon"></div>
                    </div>
                </div>
              </div>
            </div>
        );
    }

    /**
     * Toggle the start and stop of the timer.
     */
    toggleStartStop(): void {
        let timerStarted = this.state.timerStarted;
        if (timerStarted === undefined) {
            this.startTimer();
        } else {
            this.stopTimer();
        }
    }

    /**
     * Stop the timer.
     */
    stopTimer(): void {
        let timerLeft = this.calcTimerLeft();
        timerLeft = timerLeft > 0 ? timerLeft : 0;
        const timerStarted = undefined;
        clearInterval(this.state.timeoutId);
        const timeoutId = undefined;
        this.setState({
            timerLeft,
            timerStarted,
            timeoutId,
        });
    }

    /**
     * Start the timer.
     */
    startTimer(): void {
        if (this.state.timerLeft > 0) {
            const timerStarted = new Date();
            const timeoutId = setInterval(() => this.tickTock(), 1000);
            this.setState({
                timerStarted,
                timeoutId,
            });
        }
    }

    /**
     * This ticks the clock and rerenders this component.
     */
    tickTock(): void {
        const timerLeft = this.calcTimerLeft();
        if (timerLeft <= 0) {
            this.finishTimer();
        } else {
            this.updateTitle(timerLeft);
        }
        this.forceUpdate();
    }

    /**
     * Finish the timer and call the finishCallback().
     */
    finishTimer(): void {
        this.stopTimer();
        this.props.finishCallback?.();
    }

    /**
     * Reset the timer to its initial value, props.timerLength.
     * Start the timer if it was running, otherwise leave it stopped.
     */
    resetTimer(): void {
        const isRunning = this.state.timerStarted !== undefined;
        this.stopTimer();
        this.setState({
            timerLeft: this.props.timerLength,
        });
        if (isRunning) {
            this.startTimer();
        }
    }

    /**
     * Update the title of the page with the time left on the timer and its title if set.
     */
    updateTitle(timerLeft: Seconds): void {
        const title = this.props.timerTitle !== undefined ? ` - ${this.props.timerTitle}` : "";
        document.title = `${prettyPrintTimer(timerLeft)}${title} - Pomodoro Timer`;
    }

    /**
     * Calculate how long time is left of the timer.
     * Returns The number of seconds.
     */
    calcTimerLeft(): Seconds {
        const timerStarted = this.state.timerStarted;
        if (timerStarted !== undefined) {
            const now = new Date();
            return this.state.timerLeft - ((now.valueOf() - timerStarted.valueOf()) / 1000);
        }
        return this.state.timerLeft;
    }
}

interface RenderTimerValueProps {
    timerValue: string;
}

/**
 * Render the value of the timer.
 * This is to stop it jumping around.
 */
const RenderTimerValue = (props: RenderTimerValueProps): JSX.Element => {
    return (
        <div className="TimerValue">
            {props.timerValue.split("").map( (x: string, ix: number) => {
                return <div key={ix} className="TimerDigit">{x}</div>;
            })}
        </div>
    );
}

/**
 * Returns a string of the format minutes:seconds.
 */
export const prettyPrintTimer = (seconds: Seconds): string => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    let m = ""+min;
    let s = ""+sec;
    if (min < 10) {
        m = "0"+m;
    }
    if (sec < 10) {
        s = "0"+s;
    }
    return m + ":" + s;
}

export default Timer;
