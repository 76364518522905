import * as React from "react";
import { SVGProps } from "react";
const SvgCogwheel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={137.805}
    height={137.805}
    viewBox="0 0 36.461 36.461"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      style={{
        fill: "#b3b3b3",
        strokeWidth: 0.759735,
      }}
      d="M80.69 42.955h4.9l2.87 5.667H77.819ZM80.69 79.416h4.9l2.87-5.666H77.819ZM64.909 63.636v-4.9l5.666-2.871v10.642zM101.37 63.636v-4.9l-5.667-2.871v10.642Z"
      transform="translate(-64.909 -42.955)"
    />
    <path
      style={{
        fill: "#b3b3b3",
        strokeWidth: 0.759735,
      }}
      d="m68.516 50.027 3.465-3.464 6.037 1.976-7.525 7.525zM94.298 75.809l3.465-3.464-1.977-6.038-7.525 7.526zM71.98 75.809l-3.464-3.464 1.977-6.038 7.525 7.526zM97.763 50.027l-3.465-3.464-6.037 1.976 7.525 7.525z"
      transform="translate(-64.909 -42.955)"
    />
    <path
      style={{
        fill: "#b3b3b3",
        stroke: "none",
        strokeWidth: 7.12059,
      }}
      d="M314.018 173.99a57.35 57.35 0 0 0-57.35 57.348 57.35 57.35 0 0 0 57.35 57.35 57.35 57.35 0 0 0 57.35-57.35 57.35 57.35 0 0 0-57.35-57.348zm0 21.155a36.195 36.195 0 0 1 36.195 36.193 36.195 36.195 0 0 1-36.195 36.195 36.195 36.195 0 0 1-36.196-36.195 36.195 36.195 0 0 1 36.196-36.193z"
      transform="matrix(.26458 0 0 .26458 -64.909 -42.955)"
    />
  </svg>
);
export default SvgCogwheel;
